import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'
import HomeBanner from "../components/Sections/HomeBanner"
import Thought from "../components/Sections/Thought"
import LatestArticles from "../components/Sections/LatestArticles"
import About from "../components/Sections/About"

export const query = graphql`{
  prismicHomePage {
    data {
      meta_title {
        text
      }
      meta_description {
        text
      }
      thought_subtitle {
        text
      }
      thought_quote {
        text
      }
      thought_image {
        url
      }
      latest_articles_title {
        text
      }
      latest_articles_subtitle {
        text
      }
      banner_link {
        url
      }
      banner_link_text {
        text
      }
      banner_title {
        text
      }
      banner_intro {
        text
      }
      banner_image {
        url
      }
      about_title {
        text
      }
      about_content {
        text
      }
      about_author_role {
        text
      }
      about_author_name {
        text
      }
      about_author_image {
        url
      }
    }
  }
  allPrismicBlogPost(limit: 10, sort: {fields: data___date, order: DESC}) {
    nodes {
      data {
        date(formatString: "D MMMM, YYYY")
        image {
          url
        }
        summary {
          text
        }
        title {
          text
        }
      }
      uid
    }
  }
}
`

const IndexPage = ({data}) => {

  const pageData = data.prismicHomePage.data
  const posts = data.allPrismicBlogPost.nodes

  const schema = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "url":"https://anotherharriet.com/",
    "logo": "https://anotherharriet.com/logo.svg",
    "image": "https://anotherharriet.com/logo.svg",
    "email" : "anotherharriet@gmail.com",
    "name": "Harriet Toole"
  }

  return(
  <Layout>
    <Seo title={pageData.meta_title[0].text} description={pageData.meta_description[0].text} schemaMarkup={schema} />
    <HomeBanner title={pageData.banner_title[0].text} intro={pageData.banner_intro[0].text} link="/blog" linkText="Read the blog" image={pageData.banner_image.url} />
    <Thought subtitle={pageData.thought_subtitle[0].text} quote={pageData.thought_quote[0].text} image={pageData.thought_image.url} />
    <LatestArticles subtitle={pageData.latest_articles_subtitle[0].text} title={pageData.latest_articles_title[0].text} posts={posts}  />
    <About title={pageData.about_title[0].text} content={pageData.about_content[0].text} authorRole={pageData.about_author_role[0].text} authorName={pageData.about_author_name[0].text} authorImage={pageData.about_author_image.url} /> 
  </Layout>
  )
}

export default IndexPage
