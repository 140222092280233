import React from 'react'
import SwiperCore, { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BlogItem from '../BlogItem';

const LatestArticles  = ({subtitle, title, posts}) => {

  SwiperCore.use([Navigation]);

  if (!posts) return null

  return(
    <section className="container latest-articles">
      <div className="container__inner latest-articles__inner">
          <header className="latest-articles__header">
            <h6 className="latest-articles__header-subtitle">{ subtitle }</h6>
            <h2 className="latest-articles__header-title">{title}</h2>
          </header>
        <div className="">
          <Swiper 
          className="latest-articles__slideshow"
          slidesPerView={`auto`}
          grabCursor="1"
          freeMode="1"
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}>
            {posts.map((post, i) => {
              return(
              <SwiperSlide key={i}>
                <BlogItem post={post} sizes="25em" classes="blog__list-link"></BlogItem>
              </SwiperSlide>    
              )
            })}
          </Swiper>
          <div className="swiper-buttons">
            <div className="swiper-button-prev">
              <span className="screenreader-text">Previous slide</span>
            </div>
            <div className="swiper-button-next">
              <span className="screenreader-text">Next slide</span>
            </div>
          </div> 
        </div>
      </div>
    </section>
  )
}

export default LatestArticles;