import React from 'react'
import Image from '../Image'

const Thought  = ({image, subtitle, quote}) => {

  if (!image && quote) return null

  return(
      <section className="container thought">
        <Image src={image} classes="thought__image objFit"></Image>
        <div className="container__inner thought__inner">
          <h6 className="thought__subtitle">{ subtitle }</h6>
          <p className="thought__quote">“{quote}”</p>
         </div>
     </section>
  )
}

export default Thought;