import React from 'react'
import Image from '../Image';

const About  = ({title, content, authorRole, authorName, authorImage}) => {
  return(
    <section className="container about">
      <div className="container__inner about__inner">
        <h2 className="about__title">{ title}</h2>
          <p className="about__content">“{content}”</p>
        <div className="about__author">
          <Image src={authorImage} sizes="4.5em" classes="about__author-image objFit" />
          { authorName && authorRole ?
            <div className="about__author-info">
              <div className="about__author-name">{ authorName }</div>
              <div className="about__author-role">{ authorRole }</div>
            </div>
          : null }
        </div>
      </div>
    </section> 
  )
}

export default About;