import { Link } from 'gatsby'
import React from 'react'

const Button  = ({url, text, classes}) => {

  let buttonClasses = 'button '

  if(classes){
    buttonClasses += classes
  }

  return(
    <Link to={url} className={buttonClasses}>
      <span>{text}</span>
    </Link>
  )
}

export default Button;