import React from 'react'
import Button from '../Button';
import Image from '../Image';

const HomeBanner  = ({title, intro, link, linkText, image}) => {

  return(
    <section className="container home-banner">
      <div className="container__inner home-banner__inner">
        <div className="home-banner__content">
          <h1 className="home-banner__content-title">{title}</h1>
          <p className="home-banner__content-intro">{intro}</p>
          <Button url={link} text={linkText} classes="home-banner-content__button"></Button>
        </div>
        <div className="home-banner__image-container">
          <Image src={image} sizes="(min-width: 63.75em) 50vw, 100vw" classes="home-banner__image objFit"></Image>
        </div>
      </div>
    </section>
  )
}

export default HomeBanner;